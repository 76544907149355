<template>
  <div>
    <v-alert v-if="messageOk" type="success">
      {{ messageOk.message }}
    </v-alert>

    <v-alert v-if="messageKo" type="error">
      {{ messageKo.message }}
    </v-alert>

    <v-card class="elevation-0" v-if="isRoleAdmin">
      <v-card-title>
        <h2 class="primary--text">
          Historique cas patient
        </h2>

        <basic-btn
          class="ml-4"
          icon="mdi-folder-download-outline"
          @click="download()"
        >
          Exporter
        </basic-btn>

        <v-spacer></v-spacer>
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          label="Recherche"
          single-line
          hide-details
        ></v-text-field>
      </v-card-title>
    </v-card>

    <v-data-table
      :headers="headers"
      :items="formulaires"
      :search="search"
      :items-per-page="isRoleAdmin ? 15 : 10"
      class="elevation-0"
      :loading="loading"
      loading-text="Chargement des données.Veuillez patienter..."
    >
      <template v-slot:item.index="{ item }">
        <div class="text--secondary">
          {{ formulaires.indexOf(item) + 1 }}
        </div>
      </template>

      <template v-slot:item.pickupDate.date="{ item }">
        {{ new Date(item.pickupDate.date).toLocaleDateString('fr-FR') }}
      </template>

      <template v-slot:item.reason.content="{ item }">
        <div class="font-weight-bold">
          {{ displayReasons(item.reason) }}
        </div>
      </template>

      <template v-slot:item.patientAge="{ item }">
        {{ patientAgeLabel[item.patientAge] }}
      </template>
      <template v-slot:item.sex="{ item }">
        {{ sexLabel[item.sex] }}
      </template>
      <template v-slot:item.knownPatient="{ item }">
        {{ knownPatientLabel[item.knownPatient] }}
      </template>

      <template v-slot:item.actions="{ item }">
        <v-icon medium class="mr-2" @click="editItem(item)">
          mdi-pencil
        </v-icon>
        <v-icon medium @click="deleteItem(item)">
          mdi-delete
        </v-icon>
      </template>
    </v-data-table>

    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
        <v-card-title class="headline">
          <v-spacer></v-spacer>
          Supprimer le formulaire
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>
          <v-row align="center" class="mx-0 text--primary">
            <v-alert dense outlined type="error">
              La suppression d'un formulaire entraine la perte des données
              renseignées et les rémunérations associées.
            </v-alert>
            <p class="body-1">
              Cette action est irreversible. Êtes-vous sûr de vouloir supprimer
              le formulaire à l'index #{{ itemIndexToDelete + 1 }} ?
            </p>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <basic-btn color="grey darken-1" text @click="closeDelete">
            Annuler
          </basic-btn>
          <v-spacer></v-spacer>

          <basic-btn color="error" @click="deleteItemConfirm" filled>
            Supprimer
          </basic-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import FormulaireService from '../../../services/formulaire.service'

export default {
  data: () => ({
    formulaires: [],
    loading: true,
    dialogDelete: false,
    itemToDelete: null,
    itemIndexToDelete: -1,
    messageKo: '',
    messageOk: '',
    search: '',

    reasonsLabels: {
      cardio: 'Cardiovasculaire',
      dental: 'Dentaire',
      dermato: 'Dermatologie',
      diabete: 'Diabète',
      gastro: 'Gastro-entérologie/ Digestif',
      gyneco: 'Gynécologie',
      infectio: 'Infectiologie/ Fièvre',
      ophtalmo: 'Ophtalmologie',
      orl: 'ORL',
      pneumo: 'Pneumologie/Respiratoire',
      pec: 'Prise en charge de signes évocateurs de la COVID-19',
      pec_sign: 'Prise en charge de signes évocateurs de la COVID-19 & tests antigéniques',
      pec_comfort: 'Tests antigéniques de confort',
      psycho: 'Psychologie',
      tabac: 'Tabac',
      trauma: 'Traumatologie',
      uro: 'Urologie/ Urinaire',
      other: ''
    },
    patientAgeLabel: {
      infant: 'Nourisson',
      child: 'Enfant',
      young: 'Jeune Adulte',
      adult: 'Adulte',
      senior: 'Senior'
    },
    sexLabel: {
      femme: 'Femme',
      homme: 'Homme'
    },
    knownPatientLabel: {
      regularcustomer: 'Patient habituel',
      occasionalcustomer: 'Patient occasionnel',
      nope: 'Patient inconnu'
    }
  }),

  components: {
    'basic-btn': () => import('../../../core/BasicBtn')
  },

  mounted() {
    if (!this.currentUser) {
      this.$router.push('/login')
    }
    if (this.isRoleAdmin) {
      FormulaireService.getAllFormulaire().then(
        response => {
          this.loading = false
          this.formulaires = response.data
        },
        error => {
          this.loading = false
          // console.log(error.response)
        }
      )
    } else if (this.isRoleUSer) {
      FormulaireService.getFinessFormulaire().then(
        response => {
          this.loading = false
          this.formulaires = response.data
        },
        error => {
          this.loading = false
          this.messageKo = error.response
        }
      )
    }
  },

  computed: {
    headers() {
      if (this.isRoleUSer) {
        return [
          { text: '', value: 'index', sortable: false },
          { text: 'Date', value: 'pickupDate.date' },
          { text: "Motifs de la visite à l'officine", value: 'reason.content' },
          { text: 'Age', value: 'patientAge' },
          { text: 'Sexe', value: 'sex' },
          { text: 'Statut', value: 'knownPatient' },
          {
            text: '',
            value: 'actions',
            sortable: false,
            align: 'right',
            width: '100px'
          }
        ]
      }
      if (this.isRoleAdmin) {
        return [
          { text: '', value: 'index', sortable: false },

          { text: 'Date', value: 'pickupDate.date' },
          { text: 'Officine', value: 'finess' },
          { text: "Motifs de la visite à l'officine", value: 'reason.content' },
          { text: 'Age', value: 'patientAge' },
          { text: 'Sexe', value: 'sex' },
          { text: 'Statut', value: 'knownPatient' },
          {
            text: '',
            value: 'actions',
            sortable: false,
            align: 'right',
            width: '100px'
          }
        ]
      }
    },
    currentUser() {
      return this.$store.state.auth.user
    },
    isRoleUSer() {
      if (this.currentUser && this.currentUser.role) {
        return this.currentUser.role.includes('Utilisateur')
      }
      return false
    },
    isRoleAdmin() {
      if (this.currentUser && this.currentUser.role) {
        return this.currentUser.role.includes('Administrateur')
      }
      return false
    }
  },

  watch: {
    dialog(val) {
      val || this.close()
    }
  },

  methods: {
    download() {
      if (this.isRoleAdmin) {
        FormulaireService.getExport()
          .then(response => {
            let blob = new Blob([response.data], { type: 'application/*' })
            let link = document.createElement('a')
            let label =
              'export-formulaires-patients-' +
              new Date().toISOString().split('T')[0] +
              '.xlsx'
            link.href = URL.createObjectURL(blob)
            link.download = label
            link.click()
            URL.revokeObjectURL(link.href)
          })
          .catch(err => {
            this.$toasted.show(err.error, {
              type: 'error',
              position: 'top-center',
              action: {
                text: 'Cancel',
                duration: '5000',
                onClick: (e, toastObject) => {
                  toastObject.goAway(0)
                }
              }
            })
          })
      }
    },
    displayReasons(reasons) {
      let displayReasons = []
      let reasonsLabels = this.reasonsLabels

      reasons.content.forEach(function(item, i) {
        //Put the full text label
        displayReasons[i] = reasonsLabels[item]
        // Replace the 'other' by its content
        if (item == 'other') displayReasons[i] = reasons.precision
      })

      return displayReasons.join(' - ')
    },

    editItem(item) {
      this.$router.push('/edit-formulaire/' + item._id)
    },

    deleteItem(item) {
      this.itemToDelete = item._id
      this.itemIndexToDelete = this.formulaires.indexOf(item)
      this.dialogDelete = true
    },

    deleteItemConfirm() {
      let itemIndexToDelete = this.itemIndexToDelete

      FormulaireService.deleteFormulaire(this.itemToDelete).then(
        response => {
          this.messageOk = response.data
          // Delete insite the view
          // console.log(itemIndexToDelete)
          this.formulaires.splice(itemIndexToDelete, 1)
        },
        error => {
          this.messageKo = error.data
        }
      )
      this.closeDelete()
    },

    closeDelete() {
      this.$nextTick(() => {
        this.dialogDelete = false
        this.itemToDelete = null
        this.itemIndexToDelete = -1
      })
    }
  }
}
</script>
